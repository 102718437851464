#vid_desktop_content {
  min-height: 100vh;
  background: #f8f8fb;

  .progress-nav {
    font-family: "Lato";
    border-bottom: 3px solid white;
    top: -70px;
    position: absolute;
    width: 100%;
    color: white;
    height: 50px;
    flex-wrap: nowrap;

    .nav-item {
      padding: 0 18px;
      padding-bottom: 25px;
      border-bottom: 3px solid white;
      font-size: 16px;
      text-align: center;

      .nav-item-number {
        font-weight: 400;
        border: 1px solid white;
        padding: 7px 10px 6px 10px;
        border-radius: 50%;
        margin-right: 11px;
      }

      &.active {
        border-bottom: 5px solid #2b86fa;
        padding-bottom: 23px;
        font-weight: 700;

        .nav-item-number {
          background: #2b86fa;
          border-color: #2b86fa;
        }
      }
    }
  }

  #vid_desktop_topbar {
    height: 70px;
    background: white;
    box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
    display: flex;
    position: relative;
    justify-content: space-between;

    .topbar-logo {
      background: #1d71b8;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 136px;
      }
    }

    .topbar-title {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      span {
        font-weight: 400;
        font-size: 24px;
      }
    }

    .topbar-actions {
      width: auto;
      padding-right: 30px;
      display: flex;
      align-items: center;
      z-index: 555; // quick fix for overlapping title and actions, should refactor styles

      .topbar-btn {
        background: none;
        border: none;
        color: white;
        font-size: 15px;

        img {
          width: 28px;
          height: 28px;
        }
      }

      i {
        color: #495057;
        font-size: 18px;
      }

      .header-btn {
        background: white;
        border: none;
        color: #1D71B8;
        font-size: 13px;
        padding: 4px 15px 4px 8px;
        border-radius: 30px;
        margin-right: 20px
      }
    }
  }

  #main_content_col {
    width: 800px;

    #vid_desktop_card {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      align-items: center;
      margin-bottom: 80px;

      #vid_desktop_header {
        background: url("/assets/images/desktop/header-background.png") no-repeat;
        height: 280px;
        width: 100%;
        margin-top: 30px;
        background-size: cover;
        background-position: center;
        position: relative;

        #header_logo {
          position: absolute;
          z-index: 10;
          left: 58px;
          top: 35px;
          height: 31px;
          width: 141px;
        }

        #opacity_overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, #222222 0%, rgba(34, 34, 34, 0.75) 100%);
          opacity: 0.65;
        }
      }

      #vid_desktop_card_body {
        flex: 0;
        width: 700px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        border-radius: 4px;
        margin-top: -40px;
        z-index: 10;
        min-height: 545px;
        padding: 0;
        position: relative;

        #qr_parent {
          padding: 30px 130px;

          .qr-description {
            text-align: center;
            line-height: 29px;
            font-weight: 400;
            color: #2c3746;
            font-size: 19.5px;
          }

          .qr-wrapper {
            padding: 15px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            width: 200px;
            height: 200px;
            margin: auto;
            margin-top: 30px;
          }
        }

        .vid-progress {
          #vid_title {
            font-size: 20px;
          }

          #vid_icon {
            width: 105.38px;
            margin-top: 30px;
          }

          #progress_bar {
            margin-top: 25px;
            margin-bottom: 30px;
            width: 80%;
          }

          .vid-desc {
            font-size: 16px;
          }

          .main-btn {
            width: 340px;
            height: 48px;
            background: linear-gradient(180deg, #0191ff 0%, #2b69b8 99.28%);
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-family: "Lato";
            font-weight: 700;
            font-size: 16px;
            margin-top: 70px;
            border: none;
          }

          .vid-status {
            font-weight: 600;
          }
        }
      }
    }
  }
}