#welcome_wrapper.desktop {
  .welcome-column {
    font-size: 16px;
    width: 650px;
  }

  .welcome-title {
    font-size: 25px;
  }

  .welcome-desktop-body {
    padding: 35px;
  }

  #codeWrapper {
    margin: auto;
    margin-top: 30px;
    justify-content: center;

    .code_check_result_icon {
      right: 95px;
    }
  }

  #invalidCodeLabel {
    width: 360px;
    margin: auto;
    margin-top: 10px;
    text-align: center;
  }

  .resend-btn {
    margin-top: 25px;
  }
}
