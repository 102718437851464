#signatures.desktop {
  .sig-column {
    width: 800px;

    .sig-card {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      align-items: center;
      margin-bottom: 70px;

      .sig-header {
        background: url("/assets/images/desktop/signatures-header.png") no-repeat;
        height: 280px;
        width: 100%;
        background-size: cover;
        background-position: center;
        position: relative;

        #opacity_overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, #222222 0%, rgba(34, 34, 34, 0.75) 100%);
          opacity: 0.35;
        }
      }

      .sig-desktop-body {
        flex: 0;
        width: 685px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        border-radius: 4px;
        margin-top: -40px;
        z-index: 10;
        padding: 30px 100px 40px 100px;
        position: relative;

        .instr-parent {
          margin-top: 30px;
          padding: 0 80px;
        }

        .btns-row {
          margin-top: 30px;
        }
      }
    }
  }
}
