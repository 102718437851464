.contact-modal {
  border-radius: 20px;
  font-family: Lato;
  width: 335px;
  position: relative;
  bottom: 60px;
}

.contact-modal-header {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 0;
}

.contact-modal {
  .btn-close {
    position: absolute;
    top: 10px;
    right: 8px;
    padding: 8px;
  }
}

.contact-modal-title {
  font-size: 16px;
  font-weight: 700;
}

.contact-modal-subtitle {
  color: $gray-800;
  margin: 0;
  font-size: 14px;
}

.contact-item-title {
  font-weight: 700;
  color: #2B69B8;
  font-size: 16px;
  margin-bottom: 5px;
}

.contact-item-subtitle {
  color: $gray-600;
  margin: 0;
}

.contact-offcanvas {
  overflow: hidden;
  height: 33vh;
  min-height: 278px;

  &.expanded {
    height: 42vh;
    min-height: 353px;
  }

  &.offcanvas-bottom {
    border-radius: 16px 16px 0px 0px;
  }

  .offcanvas-body {
    padding-top: 24px;
  }

  .contact-modal-title {
    font-size: 24px;
  }
}