/********** Finish component *****/

.finish-column {
  padding: 0 25px;

  .verif-icon {
    width: 35%;
  }
}

.return-label-mobile{
  font-family: "Lato";
  font-style: normal;
  text-align: justify;
}