// Welcome component
#welcome_wrapper {
  margin-top: 30px;

  .welcome-column {
    padding: 0 25px;
  }

  .welcome-title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #2c3746;
  }

  #invalidCodeLabel {
    color: #da2a2a;
    font-size: 12px;
    display: block;
    text-align: center;
  }

  #codeWrapper {
    margin: 25px 10px 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &.invalid {
      .code-digit {
        border: 1px solid #d94d4d;
      }
    }

    &.disabled {
      .code-digit {
        border: 1px solid #0f9b16;
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .code-digit {
      height: 48px;
      background: #eaeaeb;
      border-radius: 4px;
      border: none;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
    }

    .code_check_result_icon {
      position: absolute;
      right: 25px;

      i {
        color: #0f9b16;
      }
    }
  }

  .resend-btn {
    border: none;
    background: transparent;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2b69b8;

    &.disabled {
      color: #2c3746;
    }

    &:active,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}
