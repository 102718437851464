#mobileMenu.desktop {
  #topMenuWrapper {
    background: transparent;
  }

  #header {
    height: 70px;

    .header-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      flex-grow: 1;
      line-height: 15px;
    }

    .header-logo {
      img {
        width: 132px;
        height: 29px;
      }
    }
  }

  #menu {
    margin: 10px auto;
    background: transparent;
    height: 70px;

    .menu-item {
      flex-direction: row;
      justify-content: center;

      &.active {
        border-bottom: 4px solid #2b86fa;

        .menu-item-label {
          font-weight: 700;
        }
      }

      .item-img-wrapper {
        width: 36px;
        height: 36px;

        .menu-item-image {
          width: 17px;
        }
      }

      .menu-item-label {
        font-family: "Lato";
        font-style: normal;
        font-size: 16px;
        color: #222222;
        margin-left: 10px;
        padding-top: 0;
      }
    }
  }
}

/* Desktop footer */

#desktop_footer {
  width: 100%;
  background: #f2f2f5;
  height: 60px;
  padding-left: 30px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  z-index: 999;
}

.btns-wrapper {
  text-align: center;

  #backBtn,
  #continueBtn {
    width: 150px;
  }

  #backBtn {
    margin-right: 20px;
    padding: 0.7rem 0.75rem;
  }
}