$headerHeight: 48px;
$menuHeight: 97px;
$totalTopHeight: $headerHeight + $menuHeight;

/****** Top menu and title *******/

#mobileMenu {
  display: flex;
  justify-content: center;

  #topMenuWrapper {
    background: #ffffff;
  }

  #header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $headerHeight;
    background: #1d71b8;

    .header-logo {
      padding-left: 16px;

      img {
        width: 100px;
        height: 22px;
      }
    }

    .header-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      flex-grow: 1;
      line-height: 15px;
    }

    .header-btn-wrapper {
      .header-btn {
        background: white;
        border: none;
        color: #1D71B8;
        font-size: 13px;
        padding: 4px 15px 4px 8px;
        border-radius: 30px;
        margin-right: 20px
      }
    }
  }

  #menu {
    height: $menuHeight;
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    margin: 0px 25px;
    position: relative;

    #mobile-menu-hr {
      position: absolute;
      width: 100%;
      border: 1px solid #c5c5c5;
      top: 13px;
    }

    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      flex-basis: 0;
      padding: 10px 0;
      border-bottom: 4px solid #c5c5c5;
      z-index: 10;

      &.active {
        border-bottom: 4px solid #2b69b8;

        .item-img-wrapper {
          background: #2b69b8;
        }

        .menu-item-label {
          color: #2b69b8;
        }
      }

      .item-img-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #c5c5c5;
        display: flex;
        justify-content: center;
        align-items: center;

        .menu-item-image {
          width: 20px;
        }
      }

      .menu-item-label {
        padding-top: 7px;
        letter-spacing: -0.01em;
        color: #c5c5c5;
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
      }
    }
  }
}

/****** Contact modal *******/

#contact-modal {
  &.offcanvas-bottom {
    border-radius: 16px 16px 0px 0px;
  }

  .offcanvas-body {
    padding-top: 24px;
  }

  .line {
    background-color: #D8D8D8;
    height: 5px;
    width: 50px;
    margin: -10px auto 20px auto;
    border-radius: 100px;
    transform: rotate(-180deg);
  }
}

/****** Preview capture footer *******/

#previewFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #222222;
  padding-bottom: 9%;

  .powered {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    color: white;
    margin: 20px;
  }

  #previewActionsRow {
    display: flex;
    justify-content: space-around;

    .preview-action {
      flex-basis: 25%;
      text-align: center;

      button {
        width: 50px;
        height: 50px;
        background: rgba(43, 105, 184, 0.2);
        border-color: transparent;
        border-radius: 50%;
        line-height: 50px;

        i {
          color: #0191ff;
          font-size: 18px;
        }

        &.primary {
          background: #0191ff;

          i {
            color: white;
          }
        }
      }

      label {
        display: block;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}