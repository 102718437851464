/******** Documents list ********/

.finance-container {
  width: 40%;
  background-color: $white;
  height: 100%;
}

#finance_desktop_wrapper {
  font-size: 14px;
  color: #495057;
  font-family: Poppins;

  .no-documents-message {
    text-align: center;
    font-size: 13px;
  }

  .document-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .document-item {
    min-height: 59px;
    width: 100%;
    padding: 10px;
    background-color: rgba(43, 105, 184, 0.15);
    display: inline-flex;

    .document-title {
      font-weight: 500;
      font-size: 14px;
      color: $gray-700;
    }

    .document-description {
      font-weight: 400;
      font-size: 12px;
      color: $gray-700;
    }

    .accord-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    .document-badge {
      font-family: "Lato";
      padding: 4px 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.1;
      margin-bottom: 4px;
    }
  }
}

/********* Top actions row *********/

.documents-actions-row {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .doc-title {
    text-align: center;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
}