/********* Capture component ********/

#captureParent {
  width: 100vw;
  height: 100vh;
  background: #171717;
  position: absolute;
  top: -$menuHeight;
  z-index: 10;

  #contractName {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    color: white;
    text-align: center;
    padding-top: 32px;
  }

  #contractPage {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.35px;
    color: white;
    width: 85%;
    margin: auto;
    background: rgba(34, 34, 34, 0.5);
    border-radius: 15px;
    margin-top: 17px;
    height: 30px;
  }

  #cameraWrapper {
    width: 100%;
    top: 136px;
    text-align: center;
    display: flex;

    #captureWrapper {
      width: 75%;
      position: absolute;
      left: 50%;
      top: 136px;
      transform: translate(-50%, 0);
      border-radius: 7px;
      padding: 20px 13px;
      border: 1px solid white;
      text-align: center;
      display: flex;
    }

    #webcam {
      width: 310px;
      height: 430px;
      position: absolute;
      border-radius: 7px;
      padding: 20px 13px;
      border: 1px solid white;
      left: 50%;
      top: 136px;
      transform: translate(-50%, 0);
    }

    #capturePreview {
      display: block;
      width: 100%;
    }
  }
}

/********* Contracts list ********/

#signature_mobile_wrapper {
  font-size: 14px;
  color: #495057;

  #contracts_card {
    background: transparent;
    margin-bottom: 60px;
    width: 100%;
    position: relative;

    #contracts_card_body {
      background: transparent;

      .no-contracts-message {
        text-align: center;
        font-size: 13px;
      }

      #accordion {
        overflow: scroll;
      }

      .accordion-item {
        border: none;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        .contract-item {
          background: linear-gradient(0deg, rgba(43, 105, 184, 0.15), rgba(43, 105, 184, 0.15)), #ffffff;
          border-radius: 5px;
          margin: 0;
          padding: 10px;

          &.open {
            border-end-start-radius: 0;
            border-end-end-radius: 0;
          }

          .contract-title {
            font-weight: 500;
            font-size: 14px;
          }

          .contract-desc {
            font-weight: 400;
            font-size: 12px;
          }

          .accord-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
          }

          .accord-print {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            margin-right: 20px;
            color: #495057;

            .print-btn {
              border: none;
              background: transparent;
            }

            i {
              color: #495057;
            }
          }

          .badges-section {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;

            .contract-badge {
              font-family: "Lato";
              padding: 4px 8px;
              font-weight: 400;
              font-size: 10px;
              display: flex;
              line-height: 1.1;
            }
          }
        }

        .rejected-reason-wrapper.ink-sign {
          margin: 0;
          padding: 0 10px 5px 10px;
          background: #dfe9f4;
          position: relative;

          .reason-label {
            color: #f46a6a;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 5px;
          }

          .rejection-description {
            color: #2c3746;
            font-size: 10px;
            line-height: 12px;
            overflow: hidden;
            position: relative;
            text-overflow: ellipsis;
            margin-bottom: 5px;

            &.more {
              height: auto;

              .see-more-btn {
                display: none;
              }
            }
          }

          .toggle-reasons-btn {
            border: none;
            background: none;
            color: #2b69b8;
            font-size: 10px;
            padding: 0;
            display: block;
          }
        }
      }
    }
  }
}

/* Ink contract pages */

.contract-pages-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  background: #ffffff;

  .contract-page {
    flex-basis: 50%;
    text-align: center;
    padding: 5px;

    .contract-page-title {
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 15px;
    }

    #contract-page-capture {
      width: 100%;
      height: 180px;
      background: #ffffff;
      border: 1px solid #eff2f7;
      border-radius: 5px;
      background: url("/assets/images/ink/img-placeholder.png") no-repeat;
      background-size: 100px;
      background-position: center;
      overflow: hidden;
      opacity: 0.5;
      display: flex;
      align-items: center;
      position: relative;

      &.has-capture {
        opacity: 1;
        background: none;
      }

      #rejectedBadge {
        font-size: 10px;
        background: #e60000;
        border-radius: 35px;
        padding: 3px 7px;
        position: absolute;
        right: 7px;
        top: 7px;
      }

      img {
        width: 100%;
      }
    }

    .page-status-btn {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 32px;
      gap: 4px;
      font-size: 12px;
      letter-spacing: -0.2px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.unclickable {
        pointer-events: none;
      }

      i {
        font-size: 14px;
      }
    }
  }

  .contract-date-separator {
    width: 100%;
    border: 1px solid #ededed;
    margin-bottom: 7px;
    margin-top: 0;
  }

  .contract-date-label {
    margin-left: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.0683571px;
    text-transform: uppercase;
    color: #999999;
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .contract-date-value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.0683571px;
    text-transform: uppercase;
    color: #2b69b8;
  }
}

/********** Continue button *********/

.continue-btn-wrapper {
  position: fixed;
  bottom: 0;
  display: block;
  right: 0;
  left: 0;
  padding: inherit;
}

/********* Top actions row *********/

.signatures-actions-row {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  #signBackBtn {
    position: absolute;
    left: 0;
    color: #2c3746;
    margin-left: 20px;
    font-size: 18px;
  }

  .sign-title {
    text-align: center;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

  #infoBtn {
    position: absolute;
    right: 0;
    color: #2b69b8;
    font-size: 23px;
    margin-right: 20px;
    border: none;
    background: none;
  }
}

/********** Offcanvas ********/

.offcanvas.offcanvas-bottom {
  height: auto !important;

  .offcanvas-header {
    padding-bottom: 0;
  }

  .offcanvas-body {
    padding-top: 0.5rem;
  }

  .how-to-use-row {
    padding: 10px;
    padding-top: 0;
    background: white;

    .title {
      font-weight: 700;
      font-size: 24px;
      color: #1a1a1a;
      letter-spacing: -0.2px;
      margin-bottom: 10px;
      text-align: center;
    }

    .list {
      padding: 0 20px;
      margin-bottom: 0;

      li {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.1px;
        color: #2c3746;

        .print-icon,
        .msg-icon {
          font-size: 15px;
          margin: 0 2px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}