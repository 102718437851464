/********** Finish component *****/

.finish-column.desktop {
  padding: 0 50px;

  .verif-icon {
    width: 25%;
  }

  .finish-name {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  }

  .finish-message {
    padding: 0 15px;
    font-family: "Lato";
    font-style: normal;
    font-size: 16px;
  }
}

.return-label{
  font-family: "Lato";
  font-style: normal;
  background-color: #DDE2FA;
  text-align: justify;
  width: 45%;
}

.divider{
  border-top: 1px solid black;
  margin: 0.5rem 0;
}

.return-label-text{
  display: flex;
  justify-content: space-around;
}

.return-label-img{
  height: 10%;
  margin: 0.25rem 0.25rem 0 0;
}

@media (min-width:1650px){

  .return-label-img{
    height: 15%;
    margin: 0.25rem 0.25rem 0 0;
  }
}