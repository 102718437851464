$border-color: #CED4F2;

.appointment-card-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.appointment-field label {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.appointment-buttons .btn {
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
}

.appointment-result {

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .box {
    padding: 15px;
    border: 1px solid $border-color;
    border-radius: 4px;
    width: 50%;
    font-family: Poppins;
    font-size: 14px;
    line-height: 24px;
    color: $gray-700;

    .appointment-date {
      max-width: 135px;
    }
  }
}

.pending-icon {
  width: 105.38px;
  margin-top: 30px;
}

.location-change {
    background: #E9F2FF;
    border-radius: 4px;
    padding: 10px;

    h6 {
    color: #74788D;
    font-size: 13px;
    line-height: 20px;
  }
}