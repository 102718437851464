#signatures {
  .sig-column {
    padding: 0 25px;

    .signatures-title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #2c3746;
      line-height: 28px;
    }

    .signatures-hr {
      border: 1px solid #444444;
    }

    .signatures-cat {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .sign-req {
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }

    #inkBtn {
      padding: 0.7rem 0.6rem;
    }
  }
}
